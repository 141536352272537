<template>
<!--  :style='loading && { blur: "18px", filter: "blur(1px)"  }'-->

  <div
    class="card text-center"
    :style="withLineup ? {minHeight: '710px'} : {minHeight: '310px'} "
    style="background: radial-gradient(#404040, #000000)"
  >
    <div class="text-center" >
      <div v-if="withTitle">
      <h2
        slot="header"
        style="
          margin-top: 30px;
          margin-bottom: 2px;
          font-size: 1.5rem;
          text-transform: uppercase;
        "
          >
            {{ event.title }}
          </h2>
          <p
            style="
          font-size: 0.8rem;
          font-weight: normal;
          color: #b5b5b5 !important;
          text-transform: uppercase;
        "
        v-if="event.metadata.caption"
      >
        {{ event.metadata.caption }}
      </p>
      </div>

      <slot>
        <router-link
          :to="event.loading ? {} : { name: this.type, params: { slug: event.slug} }"
          class="font-weight-bold"
        >
          <img
            class="card-animation-on-hover-no-bg"
            v-lazy="
              event.metadata.main_image.imgix_url +
              '?w=220&h=160&fit=fillmax&fill=blur'
            "
            style="
              z-index: 12;
              margin-top: 10px;
              box-shadow: 0px 10px 15px 0px rgb(0 0 0 / 60%);
              border-radius: 0;
            "
            v-bind:alt="event.title"
            width="220px"
            height="160px"
          />

        </router-link>

        <EventBrand :title="event.title"></EventBrand>
        <br>

        <hr class="brand-title-black" style="height: 1px"/>
        <div style="margin-top: 5px" class="text-center">

         <EventVenue :title="event.title" icon="true"></EventVenue>
          <h4
            class="tulum-party-subtitle"
            style="text-transform: none; font-size: 0.9rem;font-weight: normal;margin: 3px;"
          >
            {{
              (event.metadata.date + ' ' + event.metadata.start_time)
                | formattedDate
            }}
          </h4>
          <h5 style="font-size: 0.7rem">DOORS OPEN {{ event.metadata.start_time }}</h5>
        </div>
      </slot>

      <div
        slot="content"
        style="padding: 2rem; font-size: 0.8rem; margin-top: 15px"
        v-if="withLineup"
      >
        <div
          v-if="event.metadata.lineup"
          v-html="event.metadata.lineup"
          class="md-small-hide"
        ></div>
      </div>

      <router-link
        :class="this.loading && 'disabled' "
        :to="{ name: this.type, params: { slug: event.slug } }"
        class="btn btn-primary btn-sm"
      >
        EVENT DETAILS
      </router-link>


      <hr/>
    </div>
  </div>
</template>
<script>
import moment from 'moment';
import EventVenue from "@/components/EventCard/EventVenue";
import EventBrand from "@/components/EventCard/EventBrand";

export default {
  name: 'EventCard',
  components: {EventBrand, EventVenue},
  filters: {
    formattedDate: function attachPrettyDate(start_time) {
      const partyDate = start_time;
      const format = 'dddd, MMMM Do ';
      const formatOptions = {
        sameDay: function () {
          return moment(`${partyDate}`, 'YYYY-MM-DD HH:mm', true).isValid()
            ? `[${this.calendar()}] `
            : `[Today ~] ${format}`;
        },
        nextDay: function () {
          return moment(`${partyDate}`, 'YYYY-MM-DD HH:mm', true).isValid()
            ? `[${this.calendar()} / ] ${format}`
            : `[Tomorrow ~] ${format}`;
        },
        nextWeek: format,
        sameElse: format,
      };

      return moment(partyDate).calendar(null, formatOptions);
    },
  },
  props: {
    event: {
      type: Object,
    },
    idx: {
      type: Number,
    },
    withLineup: {
      type: Boolean,
      default: true
    },
    withTitle: {
      type: Boolean,
      default: true
    },
    loading: {
      type: Boolean,
    },
    type: {
      type: String,
      default: 'Event'
    }
  }
};
</script>

